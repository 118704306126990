















































































































































































































































































































































































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({ name: 'Deploy' })
export default class Deploy extends VueBase {
  private ruleForm1 = {
    entryName: '',
  }
  private app_name = ''
  private app_version = ''
  private app_group_id = ''
  private activeName = 'Spring-boot/Netty/Jetty/Sofa'
  private language = 'java'
  private token = ''
  private defaultTemplate = ''
  private uri = window.location.origin + ''
  private documents = []
  private tagOptions = []
  private md = {}
  private agentForm: any = {
    entryName: '',
    department: '',
    version: '',
    projectTemplate: '',
    project_groupId: '',

    appFormName: '[请输入应用名称]',
    appFormVersion: '[请输入应用版本]',
    appFormProjectGroupId: '[请选择项目]',
    appFormCheckType: '1',
    appFormModeType: '0',
  }

  private entryNameBlur(e: any) {
    ;(this.$refs.agentForm1 as any).validateField(
      'entryName',
      (validate: any) => {
        if (!validate) {
          this.agentForm.appFormName = this.agentForm.entryName
        }
      }
    )
    // ;(this.$refs.agentForm2 as any).validateField(
    //   'entryName',
    //   (validate: any) => {
    //     if (!validate) {
    //       this.agentForm.appFormName = this.agentForm.entryName
    //     }
    //   }
    // )
  }

  private versionNameBlur(e: any) {
    ;(this.$refs.agentForm1 as any).validateField(
      'version',
      (validate: any) => {
        if (!validate) {
          this.agentForm.appFormVersion = this.agentForm.version
        }
      }
    )
    // ;(this.$refs.agentForm2 as any).validateField(
    //   'version',
    //   (validate: any) => {
    //     if (!validate) {
    //       this.agentForm.appFormVersion = this.agentForm.version
    //     }
    //   }
    // )
  }

  private versionNameBlur1(e: any) {
     ;(this.$refs.agentForm2 as any).validateField(
      'version',
      (validate: any) => {
        if (!validate) {
          this.agentForm.appFormVersion = this.agentForm.version
        }
      }
    )
  }

  private entryNameBlur1(e: any) {
    ;(this.$refs.agentForm2 as any).validateField(
      'entryName',
      (validate: any) => {
        if (!validate) {
          this.agentForm.appFormName = this.agentForm.entryName
        }
      }
    )
  }

  private rules1: any = {
    // version: [
    //   {
    //     pattern: /^([a-zA-Z0-9][\w\-.]{0,28}[a-zA-Z0-9]|[a-zA-Z0-9]{1,30})$/gi,
    //     message: '请输入包含字母、数字、下划线、中横线和点号的字符串',
    //   },
    // ],
    entryName: [
      {
        pattern: /^([a-zA-Z0-9][\w\-.]{0,28}[a-zA-Z0-9]|[a-zA-Z0-9]{1,30})$/gi,
        message: '请输入包含字母、数字、下划线、中横线和点号的字符串',
        trigger: 'validate',
      },
    ],
  }
  private departmentList = []
  private projectList = []

  private activeKey = 'auto'
  private changeActiveKey(e: any) {
    this.activeKey = e.name
  }

  private searchObj = {
    project_group_name: '',
  }

  private async remoteGroup(query: string) {
    this.getGroups(query)
  }
  private groups: any = []
  private async getGroups(name?: string | undefined) {
    const res = await this.services.authority.getGroupList({
      page: 1,
      pageSize: 50,
      name: name,
    })
    if (res.status === 201) {
      this.groups = res.data
    }
  }

  private obj = {
    java: {
      key: 'JAVA',
      name: 'Java',
      term: [
        this.$t('views.deploy.java.term1'),
        this.$t('views.deploy.java.term2'),
        this.$t('views.deploy.java.term3'),
        this.$t('views.deploy.java.term4'),
        this.$t('views.deploy.java.term5'),
        this.$t('views.deploy.java.term6'),
      ],
      download: 'java -jar microview-agent.jar -m install -p <pid>',
    },
    python: {
      key: 'PYTHON',
      name: 'Python',
      term: [
        this.$t('views.deploy.python.os'),
        this.$t('views.deploy.python.term1'),
        this.$t('views.deploy.python.term2'),
        this.$t('views.deploy.python.term3'),
        this.$t('views.deploy.python.termA'),
        this.$t('views.deploy.python.termAa'),
        this.$t('views.deploy.python.termAb'),
        this.$t('views.deploy.python.termAc'),
        this.$t('views.deploy.python.termAd'),
        this.$t('views.deploy.python.term4'),
        this.$t('views.deploy.python.term4-1'),
        this.$t('views.deploy.python.term4-2'),
        this.$t('views.deploy.python.term6'),
        this.$t('views.deploy.python.term6-1'),
        this.$t('views.deploy.python.term6-2'),
        this.$t('views.deploy.python.term6-3'),
      ],
      download: 'pip3  install ./microview-agent-python.tar.gz',
      video:
        'https://huoqi-public.oss-cn-beijing.aliyuncs.com/iast/instatll_python_agent.mp4',
    },
    php: {
      key: 'PHP',
      name: 'PHP',
      term: [this.$t('views.deploy.php.term1')],
      download: 'pip3  install ./microview-agent-python.tar.gz',
      video:
        'https://huoqi-public.oss-cn-beijing.aliyuncs.com/iast/instatll_python_agent.mp4',
    },
    go: {
      key: 'GO',
      name: 'GO',
      term: [this.$t('views.deploy.go.term1')],
      download: '',
      video:
        'https://huoqi-public.oss-cn-beijing.aliyuncs.com/iast/instatll_python_agent.mp4',
    },
  }

  private languageObj: any = {
    javaSdk: '>=JDK1.8',
  }

  private radioHandleChange(val: any) {
    this.languageObj.javaSdk = val
  }

  private languageObj1: any = {
    checkType: '1',
  }

  private radioHandleChange1(val: any) {
    this.languageObj1.checkType = val
    this.agentForm.appFormCheckType = val
  }

  private languageObj2: any = {
    modeType: '0',
  }

  private radioHandleChange2(val: any) {
    this.languageObj2.modeType = val
    this.agentForm.appFormModeType = val
  }

  changeLanguage(language: string) {
    this.language = language
    if (language === 'java') {
      this.activeName = 'SpringBoot'
    }
    if (language === 'python') {
      this.activeName = 'Django'
    }
    if (language === 'php') {
      this.activeName = ''
      this.getDoc()
      return
    }
    if (language === 'go') {
      this.activeName = ''
      this.getDoc()
      return
    }
    this.getDoc()
    this.getMd()
  }
  get shell1() {
    if (this.activeKey == 'auto') {
      return `

        -javaagent:microview-agent.jar \

        -Dmicroview.project.id=${this.agentForm.project_groupId || ''} \

        -Dmicroview.app.name=${this.agentForm.appFormName} \

        -Dmicroview.app.version=${this.agentForm.appFormVersion} \

        -Dmicroview.analysis.level=${this.agentForm.appFormCheckType} \

        -Dmicroview.analysis.mode=${this.agentForm.appFormModeType}
      `
    } else if (this.activeKey == 'inject') {
      return `

        java -jar microview-agent.jar --mode install \

        --pid [进程id] \

        --microview.project.id ${this.agentForm.project_groupId || ''} \
        
        --microview.app.name ${this.agentForm.appFormName} \

        --microview.app.version ${this.agentForm.appFormVersion} \

        --microview.analysis.level ${this.agentForm.appFormCheckType} \

        --microview.analysis.mode ${this.agentForm.appFormModeType}
      `
    }
  }

  get shell() {
    return `curl -X GET "${this.uri}/microview/v2/agent/download?url=${
      this.uri
    }&language=${this.language}&token=${
      this.agentForm.department || this.token
    }" -H "Authorization: Token ${
      this.agentForm.department || this.token
    }" -o microview-agent.jar -k`
  }

  // get shell() {
  //   switch (this.language) {
  //     case 'java':
  //       return `curl -X GET "${this.uri}/api/v1/agent/download?url=${
  //         this.uri
  //       }&language=java&token=${
  //         this.agentForm.department || this.token
  //       }&projectName=${
  //         (this.agentForm.entryName && encodeURI(this.agentForm.entryName)) ||
  //         'Demo%20Project'
  //       }&projectVersion=${
  //         (this.agentForm.version && encodeURI(this.agentForm.version)) ||
  //         'V1.0'
  //       }&template_id=${
  //         this.agentForm.projectTemplate || this.defaultTemplate
  //       }" -H "Authorization: Token ${
  //         this.agentForm.department || this.token
  //       }" -o microview-agent.jar -k`

  //     case 'python':
  //       return `curl -X GET "${this.uri}/api/v1/agent/download?url=${
  //         this.uri
  //       }&language=python&token=${
  //         this.agentForm.department || this.token
  //       }&projectName=${
  //         (this.agentForm.entryName && encodeURI(this.agentForm.entryName)) ||
  //         'Demo%20Project'
  //       }&projectVersion=${
  //         (this.agentForm.version && encodeURI(this.agentForm.version)) ||
  //         'V1.0'
  //       }&template_id=${
  //         this.agentForm.projectTemplate || this.defaultTemplate
  //       }" -H "Authorization: Token ${
  //         this.agentForm.department || this.token
  //       }" -o microview-agent-python.tar.gz -k`
  //     case 'php':
  //       return `curl -X GET "${this.uri}/api/v1/agent/download?url=${
  //         this.uri
  //       }&language=php&token=${
  //         this.agentForm.department || this.token
  //       }&projectName=${
  //         (this.agentForm.entryName && encodeURI(this.agentForm.entryName)) ||
  //         'Demo%20Project'
  //       }&projectVersion=${
  //         (this.agentForm.version && encodeURI(this.agentForm.version)) ||
  //         'V1.0'
  //       }&template_id=${
  //         this.agentForm.projectTemplate || this.defaultTemplate
  //       }" -H "Authorization: Token ${
  //         this.agentForm.department || this.token
  //       }" -o php-agent.tar.gz`
  //     case 'go':
  //       return `curl -X GET "${this.uri}/api/v1/agent/download?url=${
  //         this.uri
  //       }&language=go&token=${
  //         this.agentForm.department || this.token
  //       }&projectName=${
  //         (this.agentForm.entryName && encodeURI(this.agentForm.entryName)) ||
  //         'Demo%20Project'
  //       }&projectVersion=${
  //         (this.agentForm.version && encodeURI(this.agentForm.version)) ||
  //         'V1.0'
  //       }&template_id=${
  //         this.agentForm.projectTemplate || this.defaultTemplate
  //       }" -H "Authorization: Token ${
  //         this.agentForm.department || this.token
  //       }" -o microview-go-agent-config.yaml`
  //   }
  // }
  get pythonShell() {
    return
  }
  private goDoc(url: string) {
    window.open(url)
  }

  private tagclick() {
    this.agentForm.tagshow = true
    this.$nextTick(() => {
      ;(this.$refs.agentTagInput as any).focus()
    })
  }
  private agentTagInputBlur() {
    this.agentForm.tagshow = false
  }
  private agentTagChange() {
    if (!this.agentForm.tagList.includes(this.agentForm.tagOption)) {
      this.agentForm.tagList.push(this.agentForm.tagOption)
    }
    this.agentForm.tagOption = ''
  }
  private deletag(key: any) {
    this.agentForm.tagList = this.agentForm.tagList.filter((item: any) => {
      return item !== key
    })
  }

  private async validateFormHandle() {
    await (this.$refs['agentForm1'] as any).validate()
  }
  private async onCopy() {
    await (this.$refs['agentForm1'] as any).validate()
    this.$message({
      message: '已复制',
      type: 'success',
    })
  }

  private async onCopy1() {
    this.$message({
      message: '已复制',
      type: 'success',
    })
  }

  private onError() {
    this.$message({
      message: '复制失败！',
      type: 'error',
    })
  }

  private async getMd() {
    if (this.md[this.activeName]) {
      return
    }
    const res = await this.services.deploy.getMD({
      language: this.language,
      middleware: this.activeName,
    })
    if (res.status === 201) {
      this.$set(this.md, this.activeName, res.data.desc)
    } else {
      this.$message.error(res.msg)
    }
  }

  private async downloadAgent() {
    const url = `${window.location.origin}/microview/v2/agent/download?url=${
      window.location.origin
    }&language=${this.language}&token=${
      this.agentForm.department || this.token
    }`
    window.open(url)
  }

  // private async downloadAgent() {
  //   const url =
  //     window.location.origin +
  //     '/api/v1/agent/download?url=' +
  //     window.location.origin +
  //     '/openapi&language=' +
  //     this.language +
  //     `&token=${this.agentForm.department || this.token}&projectName=${
  //       (this.agentForm.entryName && encodeURI(this.agentForm.entryName)) ||
  //       'Demo%20Project'
  //     }&projectVersion=${
  //       (this.agentForm.version && encodeURI(this.agentForm.version)) || 'V1.0'
  //     }&template_id=${this.agentForm.projectTemplate || this.defaultTemplate}`
  //   // console.log('url', url)
  //   window.open(url)
  // }
  private async getDoc() {
    const docRes = await this.services.deploy.getDocuments({
      language: this.language,
    })
    if (docRes.status === 201) {
      this.documents = docRes.data.documents
    }
  }

  private async getListProjecttemplat() {
    const res = await this.services.setting.listProjecttemplat({
      page: 1,
      page_size: 100,
    })
    if (res.status === 201) {
      this.projectList = res.data
      this.defaultTemplate = res.data[res.data.length - 1]?.id || ''
      this.agentForm.projectTemplate = this.defaultTemplate
      return
    }
    this.$message.error(res.msg)
  }
  private async getListDepartment() {
    // 部门list
    const res = await this.services.deploy.getDepartment({})
    if (res.status === 201) {
      this.departmentList = res.data
      this.token = res.data[res.data.length - 1]?.token || ''
      this.agentForm.department = this.token
      return
    }
    this.$message.error(res.msg)
  }

  async getToken() {
    const { data } = await this.services.deploy.getToken()
    this.token = data.token
  }
  private async created() {
    this.remoteGroup('')
    await this.getListProjecttemplat()
    // await this.getListDepartment()
    this.agentForm.entryName = ''
    this.agentForm.version = ''
    this.agentForm.project_groupId = ''

    this.agentForm.appFormName = ''
    this.agentForm.appFormVersion = ''
    this.agentForm.appFormProjectGroupId = ''
    await this.getMd()
    await this.getDoc()
    this.getToken()
  }
}
